<template>
  <v-app-bar
    v-if="!$vuetify.breakpoint.mobile"
    height="100"
    color="#F8F8F8"
    class="elevation-0"
    app
  >
    <v-row class="align-center">
      <v-col cols="12" sm="6" class="mt-6">
        <!-- <v-text-field
          flat
          solo
          dense
          class="rounded-pill"
          append-icon="mdi-magnify"
          placeholder="Buscar paciente (nome, idade, CID, CPF, etc)"
        ></v-text-field> -->
      </v-col>
      <v-col cols="12" sm="6" class="align-center">
        <v-row class="justify-end">
          <!-- <div class="mr-10 mt-2">
            <v-badge
              medium
              :content="notifications"
              :value="notifications"
              color="green"
              overlap
            >
              <v-icon medium class="white rounded-pill pa-1">
                mdi-bell-outline
              </v-icon>
            </v-badge>
          </div> -->
          <div class="mr-10">
            <v-menu offset-y class="ma-0 pa-0">
              <template v-slot:activator="{ attrs, on }">
                <!-- v-bind="attrs" v-on="on" -->
                <v-row
                  class="align-center text-center ma-0 pa-0"
                  v-bind="attrs"
                  v-on="on"
                  link
                >
                  <v-avatar class="mr-5" color="primary" size="45">{{
                    `${currentUser.name ? currentUser.name.slice(0, 1) : 'Não definido'}`
                  }}</v-avatar>
                  <div class="mr-5">
                    {{ currentUser.name ? currentUser.name : 'U' }}
                  </div>
                  <v-icon> mdi-chevron-down </v-icon>
                </v-row>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  link
                  @click="item.click"
                >
                  <v-list-item-title
                    :style="item.color"
                    v-text="item.title"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      notifications: 1,
      show: false,
      items: [
        // {
        //   title: "Meu perfil",
        //   color: "color: #64676C;",
        //   href: "/dashboard",
        //   click: () => console.log('Profile')
        // },
        // {
        //   title: "Ajuda",
        //   color: "color: #64676C;",
        //   href: "",
        //   click: () => console.log('ajuda')
        // },
        {
          title: "Sair",
          color: "color: red;",
          href: "",
          click: () => this.onLogout(),
        },
      ],
    };
  },
  methods: {
    ...mapActions("authentication", ["logout"]),
    async onLogout() {
      try {
        await this.logout();
        this.$router.push({ name: "Login" });
      } catch (error) {
        console.log("Logout account error: ", error);
      }
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
  },
};
</script>

<style></style>
